<div class="nav-item btn-group"
     [class.show]="menuDropdownTrigger.menuOpen"
     data-test="header-notifications-button">
  <button class="btn btn-link nav-link"
          #menuDropdownTrigger="matMenuTrigger"
          [matMenuTriggerFor]="notifyMenu"
          [ngClass]="{'has-notifications':checkUnreadNotifications()}"
          [matTooltip]="'label.Notifications' | translate">
    <span class="fas fa-bell fa-lg fa-fw"></span>
    <i class="notification-circle" *ngIf="checkUnreadNotifications()" [ngStyle]= "notificationBellCss"></i>
  </button>

  <mat-menu class="iris-dropdown-menu"
            data-test="header-notifications-menu"
            [backdropClass]="'header-notify-overlay'"
            #notifyMenu="matMenu">
    <div *ngIf="!notifications.length"
         class="empty-box p-4 my-4"
         data-test="header-no-new-notifications">
      <div class="py-2 text-disabled">
        <span class="fas fs-1 fa-bell-exclamation"></span>
      </div>
      <span class="text-medium">{{'label.notifications.NoNewNotifications' | translate}}</span>
    </div>
    <ul class="list-group">
      <li *ngFor="let notification of notifications"
          class="item notification-item"
          [attr.data-test]="'header-notifications-' + notification.subject">
        <div class="item-content flex-row flex-middle">
          <div class="left-side">
            <div class="content-header justify-content-between">
              <span class="badge bg-disabled"
                    [style.background-color]="notification.notificationPriority.color">
                {{'label.New' | translate}}
              </span>

              <h3 class="title primary-dark-blue">{{ notification.subject }}</h3>
            </div>
            <div class="content-body pt-2 pb-1"
              [innerHtml]="fixMessage(notification.message) | safeHtml">
            </div>
            <div class="text-secondary" [matTooltip]="notification.eventDate | irisTime">
              {{ notification.eventDate | irisDateAgo }}
            </div>
          </div>

          @if (notification.link) {
            <div class="flex-col-0">
              <a mat-icon-button
                 class="action-item"
                 [matTooltip]="'label.OpenDetails' | translate"
                 [href]="notification.link">
                <i class="fas fa-sm fa-arrow-square-right"></i>
              </a>
            </div>
          }

          <div class="flex-col-0">
            <button mat-icon-button
                    class="action-item"
                    [matTooltip]="'label.MarkAsRead' | translate"
                    (click)="markAsRead($event, notification)">
              <i class="fas fa-sm fa-comment-alt-check"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="notifications-buttons">
      <a class="text-accent btn btn-link text-decoration-none"
         [routerLink]="['/user-notifications']"
         (click)="menuDropdownTrigger.closeMenu()">
        {{'label.ShowAllNotifications' | translate}}
      </a>
      <button class="mark-all-as-read btn btn-default"
              [hidden]="!checkUnreadNotifications()"
              (click)="markAllAsRead($event)">
        {{'label.MarkAllAsRead' | translate}}
      </button>
    </div>
  </mat-menu>
</div>
